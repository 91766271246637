<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
              
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Navigation Menus</h4>
                  <div class="">
                    <button class="btn btn-default custom_btn custon_orange" data-toggle="modal" data-target="#new_menu">
                      <i class="fa fa-plus"></i> New Menu
                    </button>
                    <!-- moda -->
                    <div class="modal fade" id="new_menu" role="dialog">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">Create Menu
                          </div>
                          <form @submit.prevent="createMenu()">
                            <div class="modal-body">
                              <div class="error_msgs" v-if="errors">
                                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                  <ul>
                                    <li v-for="error in errorMsg" :key="error">{{error}}</li>
                                  </ul>
                                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="mbl_type">Menu Type:</label>
                                <div class="mt-3">
                                  <select class="form-control" v-model="menuType" id="">
                                    <option value="">--Select menu type--</option>
                                    <option v-for="menuoption in menuOptions" :key="menuoption.slug" :value="menuoption.slug">{{ menuoption.name }}</option>
                                  </select>
                                  <!-- <div class="input_error" v-if="v$.menuType.$error">
                                    <span v-if="v$.menuType.required.$invalid"><small>Menu type is required</small></span>
                                  </div> -->
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="mbl_type">Status:</label>
                                <div class="mt-3">
                                  <select class="form-control" v-model="menustatus" >
                                    <option value="1">Published</option>
                                    <option value="2">Unpublished</option>
                                  </select>
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="mbl_type">Description:</label>
                                <div class="mt-3">
                                  <textarea class="form-control" v-model="description" id="" cols="20" rows="10"></textarea>
                                </div>
                              </div>
                            </div>
                            
                            <div class="modal-footer">
                              <button type="button" id="create_close_btn" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                              <button type="submit" class="btn btn-orange"><span v-if="spinner"><i class="fa fa-spinner fa-spin"></i></span>Create</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <!--  -->
                  </div>
  
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchMenus()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..."   class="form-control" >
                      </div>
                    </div>
  
                    <div class="col-md-2 col-sm-12">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mt-1">Search</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <select class="form-control mt-1" @change="searchMenus()" v-model="status">
                        <option value="">--Select status --</option>
                        <option value="1">Published</option>
                        <option value="2">Unpublished</option>
                        <option value="4">Trashed</option>
                        <option value="">All</option>
                      </select>
                    </div>
                  
                  </div>
                </div>
              </div>
              <hr>
            </div>
  
            
            
              
              
             
            <div class="col-lg-12">
              <div class="filters" id="filter_bar" style="display:none">
                <div v-if="adminRoles.includes(user.role.id)">
                  <span class="btn-filter mt-1">
                    <input type="checkbox" id="uncheck" @click="checkMenus('uncheck')" indeterminate = true>
                  </span>
                  <button class="btn-filter mr-1 mt-1">
                    {{menuSelected >1 ? menuSelected + ' Menus': menuSelected + ' menu'}}  selected
                  </button>
                  <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate(1)">
                    <i class='fa fa-check' style="color:green;"></i>
                    <span v-if="methodSpinner =='publish'"><span v-html="spinner"></span> </span> <span v-else> publish</span> </button>
                  <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate(2)">
                    <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                    <span v-if="methodSpinner =='unpublish'"><span v-html="spinner"></span></span> <span v-else> Unpublish</span> </button>
                  <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate(4)">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                    <span v-if="methodSpinner == 'trash'">
                      <span v-html="spinner"></span>
                    </span>
                    <span v-else> Trash</span>
                  </button>
                </div>
              </div>
              <div class="dash_card_body pt-0">
                <div class="table_section storeList">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th width="5px">
                          <input type="checkbox" id="check_all" @click="checkMenus('check')">
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="menu in menus" :key="menu.id">
                        <td><input type="checkbox" :id="'menu_'+menu.id" @click="selectMenu(menu.id)"></td>
                        <td>
                          <router-link :to="{ name: 'menu_items', params: { id:menu.id }}">
                            {{menu.menu_type}}
                          </router-link>
                        </td>
                        <td>
                          <p>{{ menu.description }}</p>
                        </td>
                        <td>
                          <div class = "btn-group">
                            <button class="btn custom_btn btn-sm dropdown-toggle'" type="button"  >
                              <span v-if="menu.status == 1">
                                <i class='fa fa-check' style="color:green;"></i> 
                              </span>
                              <span v-else-if="menu.status == 2">
                                <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                              </span>
                              <span v-else-if="menu.status == 3">
                                <i class="fa fa-archive" aria-hidden="true"></i>
                              </span>
                              <span v-else>
                                <i class="fa fa-trash"  aria-hidden="true"></i>
                              </span>
                            </button>
                            <button v-if="adminRoles.includes(user.role.id)" type = "button" class = "btn btn-sm custom_btn btn-default dropdown-toggle" data-toggle = "dropdown" :id="'dropdownMenuButton'+menu.id" >
                              <span class = "caret"></span>
                              <span class = "sr-only">Toggle Dropdown</span>
                            </button>
                            <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButton'+menu.id">
                              <a class="dropdown-item" @click="updateStatusPerMenu(menu.id,1)" href="#">Publish</a>
                              <a class="dropdown-item" @click="updateStatusPerMenu(menu.id,2)" href="#">Unpublish</a>
                              <a class="dropdown-item" @click="updateStatusPerMenu(menu.id,4)" href="#">Trash</a>
                            </div>
                          </div>
                            
  
                        </td>
                        <td>
                          <button class="btn btn-purple btn-sm mr-1" data-toggle="modal" :data-target="`#edit_menu_${menu.id}`" @click="editMenu(menu)"><i class='fas fa-edit'></i></button>
                        </td>
                        <!-- edit modal -->
                        <div class="modal fade" :id="`edit_menu_${menu.id}`" role="dialog">
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">update {{ menu.menu_type }}
                              </div>
                              <form @submit.prevent="updateMenu(menu.id)">
                                <div class="modal-body">
                                  <div class="error_msgs" v-if="errors">
                                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                      <ul>
                                        <li v-for="error in errorMsg" :key="error">{{error}}</li>
                                      </ul>
                                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label  for="mbl_type">Menu Type:</label>
                                    <div class="mt-3">
                                      <select class="form-control" v-model="menuType" id="">
                                        <option value="">--Select menu type--</option>
                                        <option v-for="menuoption in menuOptions" :key="menuoption.key" :value="menuoption.key">{{ menuoption.value }}</option>
                                      </select>
                                      <!-- <div class="input_error" v-if="v$.menuType.$error">
                                    <span v-if="v$.menuType.required.$invalid"><small>Menu type is required</small></span>
                                  </div> -->
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label  for="mbl_type">Status:</label>
                                    <div class="mt-3">
                                      <select class="form-control" v-model="menustatus" >
                                        <option value="1">Published</option>
                                        <option value="2">Unpublished</option>
                                      </select>

                                 
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label  for="mbl_type">Description:</label>
                                    <div class="mt-3">
                                      <textarea class="form-control" v-model="description" id="" cols="20" rows="10"></textarea>
                                    </div>
                                  </div>
                                </div>
                            
                                <div class="modal-footer">
                                  <button type="button" id="create_close_btn" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                                  <button type="submit" class="btn btn-orange"><span v-if="spinner"><i class="fa fa-spinner fa-spin"></i></span>Update</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <!-- end -->
                      </tr>
  
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination" v-if="totalPages > 1">
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>
                              
                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
  
                          </div>
                            
                        </td>
                      </tr>
                    </tfoot>
                  </table>
  
                </div>
                <div v-if="loading" class="loading-cover">
                  <div class="loader"> </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'
  import ReusableDataMixins from '@/mixins/ReusableDataMixins';
 
  import Pagination from '@/pages/pagination/pagination.vue';
  import { useToast } from "vue-toastification";
  const toast = useToast();
  export default {
      components: {
          Pagination,
      },
      data() {
          return {
              menus: [],
              loading: false,
              page: 1,
              totalPages: 0,
              totalRecords: 0,
              recordsPerPage: 10,
              debounce: null,
              spinner: false,
              search:'',
              menuSelected: 0,
              status:'',
              menuIds:[],
              errorMsg: [],
              menuType: '',
              menustatus: 2,
              description:'',
              menuOptions:[]
          }
      },
      created: async function () {
          this.getMenus();
          this.getRoles();
      },
      methods: {
        checkMenus(type){
            document.querySelector('#uncheck').indeterminate = true;
            if(type === 'check'){
              if(document.getElementById('check_all').checked){
                 document.getElementById('filter_bar').style.display='block'
                 this.menuSelected = 0;
                  for(const menu of Object.values(this.menus)){
                    this.menuSelected +=1;
                    document.getElementById('menu_' + menu.id).checked = true
                    if (this.menuIds.includes(menu.id) === false) {
                          this.menuIds.push(menu.id);
                    }
                  }
              }else{
                document.getElementById('filter_bar').style.display='none'
                for(const menu of Object.values(this.menus)){
                    document.getElementById('menu_' + menu.id).checked = false
                  }
                  this.menuIds = []
              }
              
            }else{
              document.getElementById('filter_bar').style.display='none'
              document.getElementById('check_all').checked =  false
              for(const menu of Object.values(this.menus)){
                document.getElementById('menu_' + menu.id).checked = false
              }
              this.menuIds = []
            }
  
        },
        async getRoles() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/roles`;
            this.axios.get(url,config).then((response) => {
                if (response.status === 200) {
                    this.menuOptions =  response.data.data;
                }
            }).catch((err) => {
                console.log('error', err);
            }).finally(()=>  this.loading = false);

        },
        searchMenus() {
              this.page = 1;
              clearTimeout(this.debounce);
              this.debounce = setTimeout(() => {
                  this.getMenus();
              }, 1000)
          },
        selectMenu(menuId){
          if(document.getElementById('menu_'+menuId).checked){
            if(this.menuIds.includes(menuId) === false){
              this.menuIds.push(menuId);
            } 
            document.getElementById('filter_bar').style.display='block'
          } else {
            if(this.menuIds.indexOf(menuId) !== -1){
                this.menuIds = this.menuIds.filter((x) => x !== menuId);
              }
                  // checking if there is any checked box
                  for(const menu of Object.values(this.menus)){
                    if(menu.id != menuId && document.getElementById('menu_' + menu.id).checked){
                      document.getElementById('filter_bar').style.display='block'
                      break; 
                    }else{
                      document.getElementById('filter_bar').style.display='none'
                    }
                  }
                  // 
              }
              // increasing count on item selected
              this.menuSelected = 0;
              for(const menu of Object.values(this.menus)){
                if(document.getElementById('menu_' + menu.id).checked){
                  this.menuSelected +=1;
                }
              }
        },
        bulkStatusUpdate(status){
            this.updateMenuStatus(status,this.menuIds);
        },
        updateStatusPerMenu(menuId,status){
          const storeIdsArr = [];
          if(storeIdsArr.includes(menuId) === false){
            storeIdsArr.push(menuId)
          }
          this.updateMenuStatus(status,storeIdsArr)
        },
        async createMenu(){
            this.spinner =true;
            try{
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const payload = {
                    'merchant_id': parseInt(this.merchantID),
                    'menu_type': this.menuType,
                    'status': this.menustatus,
                    'description':this.description
                }
                const url = `${this.dashBaseUrl}/create-menu/${this.merchantID}`
                const response =  await this.axios.post(url,payload,config)
                if(response.status === 201){
                    this.spinner = false
                    toast.success("navigation menu created successfully");
                    document.getElementById('create_close_btn').click();
                    this.getMenus();
                }
            }catch(error){
                this.spinner = false;
                if (error.response && error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data.errors;
                }else if(error.response && error.response.status === 401){
                    this.$router.push({name: 'login'});
                }
            }
            

        },
        editMenu(menu){
            // 
            this.menuType = menu.menu_type
            this.menustatus = menu.status
            this.description = menu.description
        },
        async updateMenu(menuID){
            //
            this.spinner =true;
            try{
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const payload = {
                    'merchant_id': parseInt(this.merchantID),
                    'menu_type': this.menuType,
                    'status': this.menustatus,
                    'description':this.description
                }
                const url = `${this.dashBaseUrl}/update-menu/${menuID}/${this.merchantID}`
                const response =  await this.axios.post(url,payload,config)
                if(response.status === 201){
                    this.spinner = false
                    toast.success("navigation menu created successfully");
                    document.getElementById(`edit_menu_${menuID}`).click();
                    this.getMenus();
                }
            }catch(error){
                this.spinner = false;
                if (error.response && error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data.errors;
                }else if(error.response && error.response.status === 401){
                    this.$router.push({name: 'login'});
                }
            } 

        },
     
          async getMenus() {  
            let url = `${this.dashBaseUrl}/menus/${this.merchantID}`;
            url  = new URL(url);
            url.searchParams.set('page',this.page);
            url.searchParams.set('size',this.recordsPerPage);
            if(this.status !==''){
              url.searchParams.set('status',this.status);
            }
            if(this.search !==''){
              url.searchParams.set('search',this.search);
            }  
              this.loading = true;
              const config = {
                          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                    };
              this.axios.get(url,config).then((response) => {
                  if (response.status === 200) {
                      this.loading = false;
                      this.menus = response.data.data.data;
                      this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                      this.totalRecords = response.data.data.total   
                  }
              }).catch((error) => {
                  if (error.response.status === 401) {
                              this.$router.push({
                                  name: 'login'
                              });
                      }
              })
  
          },
          onPageChange(page) {
              this.storeIds = []
              document.getElementById('check_all').checked = false;
              document.getElementById('filter_bar').style.display='none';
              this.page = page
              this.getMenus();
          },
          onChangeRecordsPerPage() {
            this.storeIds = []
              document.getElementById('check_all').checked = false;
              document.getElementById('filter_bar').style.display='none';
              this.getMenus();
              // ds
          },
          updateMenuStatus(status, ids){
              const payload = {
                      "ids": ids,
                      "status": status
              };
              const config = {
                          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                    };
              const url =`${this.dashBaseUrl}/update-menu-status`// this.baseUrl + '/branch/publish'
              this.axios.post(url, payload,config).then((response) => {
                      if(response.status === 200){
                          this.getMenus();
                      }
                  })
                  .catch((error) => {
                      this.spinner = false;
                      if (error.response.status === 401) {
                              this.$router.push({
                                  name: 'login'
                              });
                      }
              })
              // 
          }
      },
      mixins: [ReusableFunctionMixins,ReusableDataMixins]
  }
  </script>
  